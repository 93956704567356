<template>
    <div>
        <div class="flex justify-between items-center h-12 px-4 -ml-3 mb-3 shadow-sm bg-white">
            <p>{{ title }}</p>
            <p>
                <ClientsQuickSearch
                    ref="clientSelect"
                    placeholder="Sök Klient..."
                    style="width: 700px;"
                    :options="clientSelectOptions"
                    :selected="selectedGlobalClient"
                    @on-selected="selected"
                    :inner-suffix-label="showClosebtn"
                >
                    <i class="fa-regular fa-circle-xmark cursor-pointer" @click="ClearSelectedClient()" />
                </ClientsQuickSearch>
            </p>
            <p>{{ versionNumber }}</p>
            <p @click="logout" class="cursor-pointer">
                Logout
            </p>
        </div>
        <div v-if="criticalError" class="flex justify-center">
            <p class="bg-red-500 text-white py-2 px-4 rounded mb-2 shadow-sm">
                Critical error
            </p>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import topBarApi from "./topBar.api";
import { version } from "../../../package.json";

export default {
    data() {
        return {
            criticalError: false,
            clientSelectOptions: [],
            showClosebtn: false,
            selectedGlobalClient: {},
        };
    },

    computed: {
        versionNumber() {
            return "Version " + version;
        },

        title() {
            return this.$store.state.topbarTitle;
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },

    components: { ClientsQuickSearch: () => import("../searchClientNew") },

    created() {
        this.getSelectClientOptions();
        this.setGlobalSelectedClient();
    },

    methods: {
        setGlobalSelectedClient() {
            if (this.isGlobalClientSelected) {
                this.showClosebtn = true;
                this.selectedGlobalClient = this.$store.state.topBar.selectedClient;
            }
        },

        async getSelectClientOptions() {
            this.clientSelectOptions = await topBarApi.getClientSelectOptions();
        },

        logout() {
            Vue.auth.destroyToken();
            localStorage.clear();
            this.$store.commit("topBar/clearSelectedClient");
            this.$store.commit("clearClientList");
            this.$router.push("/", () => {});
        },

        selected(client) {
            if (client.value != undefined) {
                this.$store.commit("topBar/setSelectedClient", client);
                this.$router.push({ path: "/clientDetails/" + client.value + "/general" }).catch(() => {});
            }
            this.showClosebtn = true;
        },

        ClearSelectedClient() {
            this.showClosebtn = false;
            this.$refs.clientSelect.$refs.searchInput.value = "";
            this.$store.commit("topBar/clearSelectedClient");
        },
    },
};
</script>
