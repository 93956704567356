import Vue from "vue";

export default {
    getClientSelectOptions: async () => {
        try {
            const response = await Vue.axios.get(`topbar/clientSelectOptions`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: topBarClientSelect.api.js ~ getClientSelectOptions: ~ error", error);
            return [];
        }
    },

    // getClients: async () => {
    //     try {
    //         const response = await Vue.axios.get(`booking/clients`);
    //         return response.data;
    //     } catch (error) {
    //         console.log("🚀 ~ file: topBar.api.js ~ line 19 ~ getClients: ~ error", error);
    //         return [];
    //     }
    // },
};
